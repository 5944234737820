<template>
  <div class="page">
    <van-swipe class="banner" :autoplay="5000" indicator-color="white">
      <van-swipe-item>
        <img
          class="item"
          :src="require('../../../assets/images/seller/down01.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <van-notice-bar
      left-icon="volume-o"
      text="如无法下载，请点击右上角 '...' 在浏览器中打开下载"
    />
    <van-tabs
      :active="active"
      :color="COLOR_M"
      border="true"
      @click="chartChange"
    >
      <van-tab title="分成模式" name="mode" class="mode">
        <img
          class="img"
          :src="require('../../../assets/images/seller/seller_mode.png')"
      /></van-tab>
      <van-tab title="加入流程" name="step" class="step">
        <img
          class="img"
          :src="require('../../../assets/images/seller/seller_step.png')"
      /></van-tab>
    </van-tabs>
    <div class="operate">
      <van-row>
        <van-col span="24">
          <!-- <van-button
            class="btn"
            icon="share-o"
            :color="COLOR"
            size="small"
            @click="shareShow = true"
            >立即分享</van-button
          > -->
          <van-button
            class="btn"
            icon="down"
            :color="COLOR_M"
            size="small"
            @click="downloadAndroidApp()"
            >立即下载(Android)(4.07MB)</van-button
          >
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { Tabs, Tab, Swipe, SwipeItem, NoticeBar } from 'vant'
export default {
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NoticeBar.name]: NoticeBar
  },
  data () {
    return {
      active: 'mode'
    }
  },
  methods: {
    downloadAndroidApp () {
      window.location.href = 'https://server.utopanet.com/download/wpps_v1.0.0_20230103.apk'
    }
  }
}
</script>
<style lang="less" scoped>
.banner {
  .item {
    width: 100%;
  }
}
.mode {
  margin-bottom: 50px;
  .img {
    width: 100%;
  }
}
.step {
  margin-bottom: 50px;
  .img {
    width: 100%;
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 50px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
  .business {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }
  .text {
    font-size: 12px;
    line-height: 30px;
  }
}
</style>
